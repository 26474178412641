import { ComponentsProps, ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material';
import palette from '../palette';

const MuiTextField: {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
  variants?: ComponentsVariants['MuiTextField'];
} = {
  defaultProps: {
    variant: 'outlined',
    InputLabelProps: {
      sx: {
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        color: palette.secondary.black950,
      },
    },
  },
  styleOverrides: {
    root: {
      input: {
        borderRadius: '5px',
        padding: '0',
        fontSize: '1rem',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '400',
      },
      '& .MuiInputLabel-root': {
        top: -8,
      },
      '& .MuiInputLabel-root.Mui-focused, .MuiInputLabel-shrink': {
        top: 0,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: `1px solid ${palette.secondary.white200}`,
        },
        '&:hover fieldset': {
          border: `2px solid ${palette.secondary.blue50}`,
        },
        '&.Mui-focused fieldset': {
          border: `2px solid ${palette.secondary.blue50}`,
        },
        '&.Mui-error fieldset': {
          border: `2px solid ${palette.error.error500}`,
        },
        '&:hover.Mui-error fieldset': {
          border: `2px solid ${palette.error.error500}`,
        },
        '&:hover.Mui-focused.Mui-error fieldset': {
          border: `2px solid ${palette.error.error500}`,
        },
      },
    },
  },
};

export default MuiTextField;
