import { Box, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import { Footer } from './components/layout/Footer';
import { Head } from './components/layout/Head';
import './i18n';
import theme from './theme';

export default function App({ element }: { element: ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <Head />
      <Box
        sx={{
          borderRadius: '16px',
          bgcolor: ({ palette }) => palette.background.default,
          m: '8px'
        }}
      >
        {element}
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
