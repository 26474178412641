import { ComponentsProps, ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material';
import palette from '../palette';

const MuiMenu: {
  defaultProps?: ComponentsProps['MuiMenu'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenu'];
  variants?: ComponentsVariants['MuiMenu'];
} = {
  styleOverrides: {
    paper: {
      marginTop: '8px',
      borderRadius: '5px',
      border: `1px solid ${palette.secondary.white200}`,
    },
  },
};

export default MuiMenu;
