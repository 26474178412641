const palette = {
  primary: {
    light: '#CFDDFC',
    main: '#2464EF',
    dark: '#0F4CD1',
    primary50: '#E7EEFD',
    primary100: '#CFDDFC',
    primary500: '#2464EF',
    primary600: '#0F4CD1',
  },
  secondary: {
    light: '#FAFAFA',
    main: '#121212',
    dark: '#989898',
    white0: '#FAFAFA',
    white50: '#EDEDED',
    white200: '#CCCCCC',
    black400: '#989898',
    black600: '#5E5E5E',
    black950: '#121212',
    beige50: '#F9F1E1',
    beige400: '#F4B940',
    blue0: '#D5DDF1',
    blue50: '#E5EAF6',
  },
  error: {
    light: '#F9D2D2',
    main: '#E31C1C',
    dark: '#B51717',
    error100: '#F9D2D2',
    error500: '#E31C1C',
    error600: '#B51717',
  },
  warning: {
    light: '#FFEFCC',
    main: '#FFB800',
    dark: '#DB6900',
    warning50: '#FFEFCC',
    warning500: '#FFB800',
    warning600: '#DB6900',
  },
  success: {
    light: '#ECF8EF',
    main: '#45BB5D',
    dark: '#37954A',
    success100: '#ECF8EF',
    success500: '#45BB5D',
    success600: '#37954A',
  },
  info: {
    light: '#D3E6F8',
    main: '#2273BF',
    dark: '#59A0E2',
    info100: '#D3E6F8',
    info400: '#59A0E2',
    info600: '#2273BF',
  },
  background: {
    paper: '#FAFAFA',
    background: '#FFFFFF',
  },
};

export default palette;
