import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export function Footer() {
  const { t } = useTranslation(["common"]);
  const { palette } = useTheme();

  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: "32px 16px",
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        gap: "1rem",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="caption"
          color={({ palette }) => palette.secondary.black950}
          fontWeight="600"
        >
          © 2023 Devmonk AB, Sundbyberg
        </Typography>
        <Typography
          variant="caption"
          color={({ palette }) => palette.secondary.black950}
          fontWeight="400"
        >
          559427-5207
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography
          mb="0.4rem"
          variant="body2"
        >
          {t("navbar.language")}
        </Typography>
        <Button
          disableRipple
          size="small"
          sx={{
            "&.MuiButton-root": {
              justifyContent: "flex-start",
              padding: 0,
            },
            "&:hover": {
              bgcolor: "transparent",
            },
          }}
          onClick={(e) => {
            window.localStorage.setItem("i18nextLng", "en");
            window.location.reload();
          }}
          variant="text"
        >
          English
        </Button>
        <Button
          disableRipple
          size="small"
          sx={{
            "&.MuiButton-root": {
              justifyContent: "flex-start",
              padding: 0,
            },
            "&:hover": {
              bgcolor: "transparent",
            },
          }}
          onClick={(e) => {
            window.localStorage.setItem("i18nextLng", "sv");
            window.location.reload();
          }}
          variant="text"
        >
          Svenska
        </Button>
      </Box>
    </Container>
  );
}
