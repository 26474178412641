import palette from '../palette';

const MuiTablePagination = {
  styleOverrides: {
    root: {
      '& .MuiTablePagination-selectLabel': {
        fontSize: 14,
        color: palette.secondary.black950,
        fontWeight: '400',
      },
      '& .MuiTablePagination-displayedRows': {
        fontSize: 14,
        color: palette.secondary.black950,
        fontWeight: '400',
      },
    },
  },
};

export default MuiTablePagination;
