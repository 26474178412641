import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  h1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '4rem',
    lineHeight: '88px',
  },
  h2: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '2.875rem',
    lineHeight: '64px',
  },
  h3: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '2rem',
    lineHeight: '40px',
  },
  h4: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '1.5rem',
    lineHeight: '32px',
  },
  body1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '1rem',
    lineHeight: '24px',
  },
  body2: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '24px',
  },
  subtitle1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    lineHeight: '24px',
    fontWeight: '400',
  },
  subtitle2: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '24px',
  },
  caption: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.75rem',
    lineHeight: '16px',
    fontWeight: '400',
  },
};

export default typography;