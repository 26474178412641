import { ComponentsProps, ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material';
import palette from '../palette';

const MuiButton: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
} = {
  defaultProps: {
    disableElevation: true,
    variant: 'text',
    sx: {
      ':hover': {
        bgcolor: palette.secondary.white50,
      },
    }
  },
  styleOverrides: {
    root: {
      padding: '4px 8px',
      color: palette.secondary.black600,
      borderRadius: '6px',
      '&.MuiButton-startIcon': {
        marginLeft: '0px',
        marginRight: '8px',
      },
    },
  },
};

export default MuiButton;
