import { createTheme } from '@mui/material/styles';
import components from './theme/components';
import palette from './theme/palette';
import typography from './theme/typography';

const theme = createTheme({
  typography,
  components,
  palette
});

export default theme;