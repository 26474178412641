import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { defaultLanguage, supportedLanguages } from './langs';

import commonEn from './locales/en/common.json';
import commonSv from './locales/sv/common.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    common: commonEn,
  },
  sv: {
    common: commonSv,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: Object.keys(resources.sv),
    defaultNS,
    resources,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['path', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,
    },
    supportedLngs: supportedLanguages,
    fallbackLng: defaultLanguage,
  });

export default i18n;
