import { Theme } from '@emotion/react';
import { ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material';

const MuiSelect: {
  defaultProps?: ComponentsProps['MuiSelect'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSelect'];
  variants?: ComponentsVariants['MuiSelect'];
} = {
  styleOverrides: {
    select: {
      borderRadius: '5px',
      padding: '6.57px',
      fontSize: '14px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
    },
  },
};

export default MuiSelect;
