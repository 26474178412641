import MuiAutocomplete from "./components/MuiAutocomplete";
import MuiButton from "./components/MuiButton";
import MuiMenu from "./components/MuiMenu";
import MuiPagination from "./components/MuiPagination";
import MuiSelect from "./components/MuiSelect";
import MuiTablePagination from "./components/MuiTablePagination";
import MuiTextField from "./components/MuiTextField";
import palette from "./palette";

const components = {
  MuiButton,
  MuiTextField,
  MuiTablePagination,
  MuiPagination,
  MuiAutocomplete,
  MuiMenu,
  MuiSelect,
  MuiInputLabel: {
    styleOverrides: {
      root: {
        top: -10,
        left: -6,
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  MuiAccordion: {
    defaultProps: {
      TransitionProps: {
        unmountOnExit: true,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: '5px',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '5px',
        boxShadow: 'none',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: '400',
        color: palette.secondary.black950,
      },
    },
  },
};

export default components;