import { ComponentsProps, ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material';
import palette from '../palette';

const MuiPagination: {
  defaultProps?: ComponentsProps['MuiPagination'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiPagination'];
  variants?: ComponentsVariants['MuiPagination'];
} = {
  styleOverrides: {
    root: {
      color: palette.secondary.black950,
      justifyContent: 'center',
      display: 'flex',
      padding: '16px 0',
      '& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
        backgroundColor: palette.primary.primary100,
      },
    },
  },
};

export default MuiPagination;
