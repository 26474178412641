import { ComponentsProps, ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material';

const MuiAutocomplete: {
  defaultProps?: ComponentsProps['MuiAutocomplete'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAutocomplete'];
  variants?: ComponentsVariants['MuiAutocomplete'];
} = {
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-root': {
        padding: '0',
        width: 100,
      },
    },
    option: {
      fontSize: 14,
      fontWeight: 400,
    },
    paper: {
      width: 'fit-content',
    },
  },
};

export default MuiAutocomplete;
